import { PageProps } from 'gatsby';
import { CollectionData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Collection';
import List from '~/containers/Espace/List';
import params from '~/params/collection.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const PageAdminCollections: FC<PageProps & UserProps> = ({
  location,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <List
        displayArchive={false}
        itemList={Item}
        model={
          new CollectionData({
            params,
            wheres: {
              parent: '',
            },
          })
        }
        removeAddButton
        search={location.search}
        title={t('collections.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminCollections, 'admin');
